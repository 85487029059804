<template>
    <div>
      <Home_navbar HeaderClass="fixed-top"></Home_navbar>
      <!-- Breadcrumbs section starts here  -->
      <section class="breadcrumbs">
        <div class="container">   
          <div class="d-flex justify-content-between align-items-center">
             <h2> Awards & Recognition </h2>
            <ol>
              <li><router-link to="/">Home</router-link></li>
              <li>Awards</li>
            </ol>
          </div>  
        </div>
      </section>

      <!-- ======= Article Section Main & Sidebar ======= -->
      <section class="blog">
        <div class="container">
          <div class="row">
            <!-- The main article section starte here -->
            <div class="col-lg-8">
                <!-- Portfolio Search Box & Add Button-->
                <div>
                  <b-row>
                    <b-col sm="12"><b-form-input v-model="SearchBy" placeholder="Search by Award name"></b-form-input></b-col>
                    <!-- <b-col sm="4"><b-button>Submit Your Photos</b-button></b-col> -->
                  </b-row>    
                </div>
                <!-- Portfolio card Listing goes here -->
                <div v-for="Data in SearchList" v-bind:key="Data.id">
                  <b-row class="row no-gutters profile-card" >
                    <div class="col-md-4 Thira profile-card-5">
                      <div class="card">
                        <div class="card-img-block">
                          <router-link :to="{name:'awards-detail', params:{ AwardId:Data.awards_id}}"><img class="card-img-top" v-bind:src="Data.awards_photo" alt="Image"></router-link>
                        </div>
                      </div>
                    </div>
                      <div class="col-md-8 Thira profile-card-5">
                        <div class="card-body event-desc-block">
                          <h5 class="page-title-text"><router-link :to="{name:'awards-detail', params:{ AwardId:Data.awards_id}}">{{Data.awards_name}}</router-link></h5>
                          
                          <!-- <p>{{Data.troupe_pgm_name}} 
                             {{Data.prof_city+' | '+Data.prof_state+' | '+Data.prof_country}}</p> -->
                          <p class="card-text pre-formatted desc" v-if="Data.awards_description.length<180"><span v-html="Data.awards_description"></span>
                          <p v-else><span v-html="Data.awards_description.substring(0,180)+' ...'"></span></p>                        
                          <div class="read-more">
                            <router-link :to="{name:'awards-detail', params:{ AwardId:Data.awards_id}}">Read more</router-link>
                          </div>  
                        </div> 
                      </div>
                  </b-row>    
                </div>                
            </div>
            <!-- Article Right side bar section starts here -->
            <div class="col-lg-4">
              <div class="sidebar">
                <!-- sidebar search box -->
                <!-- <h3 class="sidebar-title">Search</h3>
                <div class="sidebar-item search-form">
                  <form action="">
                    <input type="text">
                    <button type="submit"><i class="icofont-search"></i></button>
                  </form>
                </div> -->
                <!-- sidebar Categories -->
                <!-- <h3 class="sidebar-title">Categories</h3>
                <div class="sidebar-item categories">
                  <ul>
                    <li><a href="#">Dance <span>(25)</span></a></li>
                    <li><a href="#">Music <span>(12)</span></a></li>
                    <li><a href="#">Drama <span>(5)</span></a></li>
                    <li><a href="#">Folk Arts <span>(22)</span></a></li>
                    <li><a href="#">Instruments <span>(8)</span></a></li>
                    <li><a href="#">Kshetra Vadyam <span>(14)</span></a></li>
                    <li><a href="#">Performing Arts <span>(14)</span></a></li>
                    <li><a href="#">Ritual Arts <span>(14)</span></a></li>
                  </ul>
                </div>  -->
                <!-- sidebar recent posts-->
                <SideRecentPerformers/>
                <!-- sidebar Filter By Performers Category-->
                <SideFilterPerformers/>  
              </div>

            </div>
            <!-- Article Right side bar Ends here -->
          </div><!-- End row -->
        </div><!-- End container -->
      </section><!-- End Blog Section -->        

      <!-- ======= Inject Footer section here  ======= -->
      <Home_footer/>
    
    </div><!-- End Main Div  -->
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'
import { CardPlugin } from 'bootstrap-vue'

Vue.use(CardPlugin)
Vue.use(Vueaxios,axios)

  export default {
  name:"Awards",
  metaInfo: {
    title: "Thiraseela :: ",
    titleTemplate: "%s Performing Arts Awards | Artists Portfolios | Book Stage Shows | Online Program Booking"
  },      
    data() {
      return {
        SearchBy: "",
        RespArray: [],
      }
    },
    mounted()
    {
      this.ReadAllAwards()
    },
    methods: {
      ReadAllAwards(){  
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadAllAwards')
        .then((resp)=>{
        this.RespArray=resp.data.AllAwards;
      })
      },
    },
    computed: {
    SearchList: function(){
        return this.RespArray.filter((RespArray) => {
        return RespArray.awards_name.toLowerCase().match(this.SearchBy.toLowerCase())
      });
    },   
  }
  }
</script>

<style scoped>

.profile-card {
    margin-top:35px;
    box-shadow:0 0 10px rgba(0,0,0,0.63);
    border-radius:5px;
}
.profile-card-5{
    /* box-shadow:0 0 10px rgba(0,0,0,0.63); */
    border-radius:5px;
    height: 202px;
    /* height: 450px; */
    /* height: 475px; */
    /* overflow: hidden;     */
}
.profile-card-5 .card-img-block {
    width: 91%;
    margin: 0 auto;
    position: relative;
    top: -20px;
}
.profile-card-5 .event-desc-block {
  /* border-radius:5px; */
  height: 250px;
  overflow: hidden;

}
.profile-card-5 .card-img-block img{
    border-radius:10px;
    height: 200px;
    overflow: hidden;
}
.profile-card-5 h5{
    color:#e75480;
    font-weight:800;
    font-size:16px;
}
.profile-card-5 p{
    font-size:14px;
    font-weight:300;
}
.pre-formatted {
  white-space: pre-line;
  text-align: justify;
  /* max-height: 450px;
  overflow: auto; */
  display: inline-block;
}
.page-title-text h5{
  color:#e75480;
  font-weight:800;
  font-size:16px;
  margin-left:10px;
}
</style>
